@import "../../../styles/colors";

.infoLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;

  .infoHeading {
    color: $dark-purple;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.8;
    margin: 0;
  }

  .infoDescription {
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.8;
    background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
}