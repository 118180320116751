@import "../../../styles/variables";

.vs_main_frame {
  margin: 20px auto;
  display: flex;

  @media #{$phones-big} {
    margin: 10px auto;
  }
}
