@import "../../../styles/colors";

.inputLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  color: $dark-purple-transparent;
  width: 220px;
  padding: 14px 0;
  border: 1px solid $purple-border;

  &::before {
    display: none;
  }

  input {
    display: none;
  }

  span {
    font-weight: 500;
    line-height: 22px;
    font-size: 14px;
    color: $dark-purple;
  }
}
