@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 35px 0;

  @media #{$tablets} {
    flex-direction: column;
    justify-content: center;
  }

  .videoContainer {
    width: fit-content;
    height: fit-content;

    video {
      max-height: 600px;

      @media #{$tablets-big} {
        max-height: 450px;
      }
    }
  }

  .textContainer {
    width: 50%;
    max-width: 456px;
    margin-left: 120px;

    @media #{$tablets} {
      max-width: 90%;
      width: 90%;
      margin: 40px 0 0;
    }

    h2 {
      color: $dark-purple;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.4;

      @media #{$desktop} {
        font-size: 38px
      }

      @media #{$tablets-big} {
        font-size: 32px
      }

      @media #{$tablets} {
        font-size: 28px;
        text-align: center;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.8;
      color: $dark-purple;
      margin: 16px 0 24px;

      @media #{$desktop} {
        font-size: 16px
      }

      @media #{$tablets} {
        text-align: center;
      }
    }

    .time {
      display: flex;
      gap: 8px;

      @media #{$tablets} {
        justify-content: center;
        text-align: center;
      }

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.8;
        color: $dark-purple-transparent;
      }
    }
  }
}

.footerButton {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 14px 20px;
  }
}
