@import "../../../styles/colors";

.cropFace {
  height: 100%;
  border: 1px solid $gray-border;
  border-radius: 24px;

  overflow: hidden;
  position: relative;
  cursor: grab;
  user-select: none;
  transform-origin: center;
  touch-action:none;

  &:active {
    cursor: grabbing;
  }

  .invisibleCrop {
    position: absolute;

    &__cropping {
      width: 172px;
      height: 256px;
      clip-path: url(#faceMaskClipPath);
      -webkit-clip-path: url(#faceMaskClipPath);
    }
  }

  .visibleCrop {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__mask {
      position:relative;
    }
  }

  &__image {
    user-select: none;
    position: absolute;
  }
}

