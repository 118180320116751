@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  justify-content: center;
  margin-top: 176px;

  @media #{$phones-big} {
    margin-top: 75px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 394px;

    .logo {
      margin-bottom: 19.5px;
      width: 242px;
    }

    p {
      color: $dark-purple-transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.8;
      margin: 0 0 60px;

      @media #{$phones-big} {
        text-align: center;
      }
    }

    .button {
      border: none;
      padding: 14px 0;
      width: 100%;

      span {
        margin: 0 auto;
      }
    }
  }
}