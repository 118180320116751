.verifyEmailTitle {
  margin-bottom: 0;
  text-align: center;
}

.followInstructionsText {
  margin-bottom: 40px;
  text-align: center;
}

.linkButtonContainer {
  text-decoration: none;
}