@import "../../../styles/colors";

.giftoonCard {
  position: relative;
  border-radius: 12px;
  width: 264px;
  height: 400px;

  .giftoonImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .focusedCard {
    cursor: pointer;
    background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    h2 {
      text-align: center;
      color: $white;
      font-weight: 700;
      font-size: 20px;
      margin-top: 166px;
      line-height: 140%;
    }

    p {
      color: $white;
      margin: 0 auto 16px;
      font-weight: 400;
      font-size: 18px;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      margin: 0 auto;

      .cardButton {
        -webkit-text-fill-color: $white;
        -webkit-background-clip: inherit;
        font-weight: 500;
        font-size: 14px;
        background: transparent;
        border: 1px solid $gray-transparent;
        border-radius: 6px;
      }
    }
  }

  &:hover {
    .focusedCard {
      z-index: 1;
    }
  }
}

