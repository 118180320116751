@import "../../../styles/colors";
@import "../../../styles/variables";

.videoModal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  background-color: $dark-purple-transparent;
  border-radius: 24px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  @media #{$phones-big} {
    gap: 24px;
  }

  @media #{$phones} {
    gap: 20px;
  }

  .videoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 60px;
    height: 45px;

    @media #{$phones-big} {
      width: 65px;
      height: 45px;
    }
  }
}
