@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  margin: 67px 0 140px 0;
  display: block;

  @media #{$tablets} {
    margin: 30px 0 50px;
  }

  h1 {
    width: 100%;
    margin-bottom: 52px;

    @media #{$tablets-big} {
      font-size: 35px;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media #{$tablets} {
      align-items: center;
      flex-direction: column;
    }

    .imageContainer {
      max-width: 509px;
      width: 55%;
      margin-left: 25px;

      @media #{$tablets} {
        max-width: 100%;
        width: 100%;
        margin: 25px 0 0;
      }

      img {
        width: 100%;
      }
    }

    .textContainer {
      max-width: 548px;
      width: 45%;

      @media #{$tablets} {
        max-width: 100%;
        width: 100%;
      }

      p {
        color: $dark-purple;

        @media #{$tablets-big} {
          font-size: 13px;
        }
      }
    }
  }
}

