@import "../../../styles/variables";
@import "../../../styles/colors";

.container {
  background: $white;
  border: 1px solid $gray-border;
  border-radius: 12px;
  max-width: 440px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;

  @media #{$phones-big} {
    padding: 20px;
  }

  .heading {
    @media #{$phones-big} {
      font-size: 28px;
    }
  }

  .description {
    color: $dark-purple-transparent;
    margin: 8px 0 40px 0;
  }

  .okButton {
    width: 100%;
    text-align: center;
    height: 52px;
    justify-content: center;
  }
}
