@import 'fonts';
@import 'variables';
@import 'colors';

body {
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h4 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  font-family: 'Poppins', sans-serif;
  border: none;
  background: #fff;
  &:hover {
    cursor: pointer;
  }
}

input, label   {
  font-family: 'Poppins', sans-serif;
}

.container {
  flex: 1;
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;

  @media #{$desktop} {
    max-width: 960px;
  }

  @media #{$tablets-big} {
    max-width: 737px;
  }

  @media #{$tablets} {
    max-width: 536px;
  }

  @media #{$phones-big} {
    max-width: 100%;
    padding: 0 15px;
  }
}

html {
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

header {
  box-shadow: inset 0px -1px 0px #E1E2E5;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  transition: .3s;
  z-index: 20;

  &.headerFixed {
    position: fixed;
  }

  @media #{$tablets} {
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 20;
  }
}

img {
  display: block;
}

h1 {
  color: $dark-purple;
  font-weight: 700;
  font-size: 32px;
}

* {
  box-sizing: border-box;
}
