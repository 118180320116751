@import "../../../styles/variables";

.mediaContainer {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 0;
  align-items: center;

  li {
    list-style: none;
    cursor: pointer;
    display: flex;
    line-height: 1;

    transition: 0.2s;
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }

    .mediaIcon {
      width: 40px;

      @media #{$phones-big} {
        width: 30px;
      }
    }
  }
}