@import "../../../styles/colors";
@import "../../../styles/variables";

.slider {
  border: 1px solid $gray-border;
  border-radius: 24px;
  width: 552px;
  height: 425px;

  @media #{$tablets} {
    width: 350px;
    height: 300px;
  }

  @media #{$phones} {
    width: 260px;
  }

  @media #{$phones-mini}{
    width: 200px;
  }

  .dots {
    bottom: -22px;

    li {
      margin-left: -4px;
      margin-right: -4px;

      button:before {
        font-size: 8px;
      }
    }

    :global(li.slick-active button:before) {
      background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      margin-top: 6px;
      margin-left: 6px;
      width: 7px;
      height: 7px;
      border-radius: 7px;
      content: "";
    }
  }

  .arrow {
    &:global(.slick-next:before), &:global(.slick-prev:before) {
      content: '';
    }
  }

  .imageContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 90%;
    max-height: 90%;
  }

  :global(.slick-list) {
    height: 100% !important;
    :global(.slick-track) {
      height: 100%;
      :global(.slick-slide) {
        height: 100%;
        & > div {
          height: 100%;
          & > div {
            height: 100%;
          }
        }
      }
    }
  }
}
