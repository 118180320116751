@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  @media #{$tablets} {
    width: 50%;
    text-align: center;
    margin-bottom: 30px;
  }

  .sectionHeading {
    color: $white;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 0;
  }

  .sectionList {
    list-style: none;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 0;
    gap: 8px;
    margin-bottom: 0;

    li {
      font-weight: 400;
      font-size: 16px;

      a {
        color: $white;
        cursor: pointer;
        text-decoration: none;
        transition: 0.2s;

        &:hover {
          color: $light-purple;
        }
      }
    }
  }
}
