@import "./colors";

.authentificationContainer {
  color: $dark-purple-transparent;
  margin: 72px auto 20px auto;
  max-width: 440px;
  padding: 40px;
  border: 1px solid $gray-border;
  border-radius: 12px;
  width: 100%;
}

.buttonContainer {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    padding: 14px 0;
    width: 100%;
  }
}