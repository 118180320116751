@import "../../../styles/colors";
@import "../../../styles/variables";

.modal {
  position: relative;
  background-color: $white;
  border-radius: 4px;
  padding: 10px;

  .modalContent {
    display: grid;
    width: 60vw;
    height: 60vh;
    overflow-x: scroll;
    box-sizing: border-box;

    @media #{$phones-big} {
      width: 90vw;
      height: 90vh;
    }
  }

  .imagesList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
    grid-gap: 1rem;
    justify-items:center;
    padding: 0;
    margin: 0;
    list-style: none;

    .imagesListItem {
      list-style: none;
      width: 150px;
      height: 150px;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .giftoonSmile {
      grid-column: 1/-1;
    }
  }
}

.button {
  background: linear-gradient($light-purple, $standard-purple);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
}

.crossButton {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 40px;
  height: 40px;
}
