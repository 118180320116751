@import "../../../styles/colors";

.container {
  max-width: 100%!important;
  padding: 0!important;

  .instruction {
    height: 100vh;
  }
}
