@import '../../../styles/colors';

.datePickerContainer {
  padding: 32px;
  background: $white;
  border-radius: 12px;
  border: 1px solid $gray-border;

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .cancelButton {
      padding: 14px 0;
      min-width: 122px;

      span {
        margin: 0 auto;
      }
    }

    .applyButton {
      padding: 14px 0;
      min-width: 144px;

      span {
        margin: 0 auto;
      }
    }
  }
}

