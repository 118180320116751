@import "../../../styles/colors";
@import "../../../styles/variables";

.container {
  margin-bottom: 80px!important;

  @media #{$tablets-big} {
    margin-bottom: 40px!important;
  }

  @media #{$phones-big} {
    margin-bottom: 30px!important;
  }

  .heading {
    text-align: center;
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 98px;

    @media #{$desktop} {
      font-size: 38px
    }

    @media #{$tablets-big} {
      margin-bottom: 50px;
      font-size: 32px
    }

    @media #{$tablets} {
      font-size: 28px;
    }

    @media #{$phones-big} {
      margin-bottom: 30px;
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$desktop} {
      justify-content: center;
    }
  }
}