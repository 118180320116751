@import "../../../styles/colors";

.changePasswordSection {
  margin-top: 40px;

  .changePasswordHeading {
    color: $dark-purple;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .passwordWarning {
    color: $dark-blue;
    font-weight: 400;
    font-size: 12px;
    margin: 8px 0 32px;
  }

  .saveButton {
    background: $purple-border;
    color: $light-purple;
    -webkit-text-fill-color: $light-purple;
    -webkit-background-clip: inherit;
    border: none;
    padding: 14px 32px;
    margin-top: 32px;

    &:disabled {
      background: $gray-border;
      color: $standard-purple;
      -webkit-text-fill-color: $standard-purple;
    }
  }
}