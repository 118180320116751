@import "../../../styles/variables";

.container {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;

  @media #{$tablets} {
    width: 100%;
  }

  .videoWrapper {
    position: relative;

    .videoPlayerContainer {
      width: fit-content;
      height: fit-content;

      .videoPlayer {
        width: fit-content;
        height: fit-content;

        video{
          max-height: 600px;

          @media #{$tablets-big} {
            max-height: 400px;
          }
        }
      }
    }
  }

  .brandImage {
    width: 100%;

    @media #{$tablets} {
      width: 80%;
    }

    @media #{$phones-big} {
      width: 100%;
    }
  }
}