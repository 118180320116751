@import "../../../styles/colors";
@import "../../../styles/variables";

.shopBrandsSection {
  margin-top: 56px;

  h1 {
    text-align: center;
    font-size: 40px;
    color: $dark-purple;

    @media #{$desktop} {
      font-size: 38px
    }

    @media #{$tablets-big} {
      font-size: 32px
    }

    @media #{$tablets} {
      font-size: 28px;
    }
  }

  p {
    text-align: center;
    color: $dark-purple-transparent;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    margin: 12px auto 32px auto;
    max-width: 60%;

    @media #{$tablets} {
      font-size: 16px
    }

    @media #{$phones-big} {
      font-size: 14px
    }
  }

  .couponInput {
    margin: 0 auto;
    max-width: 60%;

    @media #{$phones-big} {
      max-width: 80%;
    }

    @media #{$phones} {
      max-width: 100%;
    }
  }
}
