@import "../../../styles/colors";

.priceBlock {
  hr {
    background: #D9D9D9;
    margin: 0;
    border: 0;
    height: 1px;
    &.hr {
      margin-bottom: 15px;
    }
  }
}

