@import "../../../styles/colors";

.modalContainer {
  word-break: break-word;
  position: relative;
  background: $white;
  border-radius: 12px;
  max-width: 360px;
  padding: 32px 32px 53px;
  margin: 0 20px;

  .button {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .infoIcon {
    margin: 20px auto 16px;
  }

  .heading {
    text-align: center;
    color: $dark-purple;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  .modalDescription {
    color: $dark-purple;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;
  }
}