@import "../../../styles/colors";
@import "../../../styles/variables";

.footer {
  background: $white;
  border-top: 1px solid $gray-border;
  padding: 22px;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;

  .buttonsContainer {
    display: flex;
    justify-content: space-between;

    .rightButtonContainer {
      display: flex;
      gap: 16px;
    }
  }

  .withExtraButton {
    @media #{$phones-big} {
      display: flex;
      align-items: center;
      flex-flow: column-reverse;
    }

    .rightButtonContainer {
      @media #{$phones-big} {
        margin-bottom: 10px;
      }
    }
  }
}
