@import "../../../styles/colors";
@import "../../../styles/variables";

.block {
  margin-bottom: 80px !important;

  @media #{$tablets-big} {
    margin-bottom: 40px !important;
  }

  @media #{$phones-big} {
    margin-bottom: 30px !important;
  }

  .container {
    background: $lightest-pink2;
    border-radius: 72px;
    width: 100%;
    padding: 77px 30px 85px;
    margin: 0 auto;

    @media #{$tablets-big} {
      padding: 50px 25px;
      border-radius: 40px;
    }

    @media #{$phones-big} {
      padding: 25px;
    }

    .wrapper {
      width: 100%;
      max-width: 720px;
      margin: 0 auto;

      .heading {
        text-align: center;
        color: $standard-purple2;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.4;
        margin-bottom: 42px;

        @media #{$desktop} {
          font-size: 36px;
        }

        @media #{$tablets-big} {
          font-size: 32px;
        }

        @media #{$tablets} {
          font-size: 28px;
        }

        @media #{$phones-big} {
          font-size: 24px;
        }
      }

      .description {
        text-align: center;
        color: $standard-purple2;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.8;
        margin-bottom: 42px;

        @media #{$tablets-big} {
          font-size: 18px;
        }

        @media #{$phones-big} {
          font-size: 16px;
        }
      }

      .button {
        padding: 14px 20px;
        margin: 0 auto;
        background: $purple-gradient;
        border-radius: 15px;
        border: none;

        span {
          font-weight: 700;
          font-size: 18px;
          text-transform: uppercase;
        }
      }
    }
  }
}