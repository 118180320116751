@import "../../../styles/colors";

.react-datepicker {
  border: none;
  font-family: 'Poppins', sans-serif;

  .react-datepicker__header {
    background: $white;
    border: none;

    .react-datepicker__current-month {
      color: $dark-purple;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .react-datepicker__month {
    margin: 0;

    .react-datepicker__week {
      &:first-child {
        border-radius: 6px;

        .react-datepicker__day {
          &:first-child {
            border-radius: 6px 0 0 0;
          }
          &:last-child {
            border-radius: 0 6px 0 0;
          }
        }
      }

      &:last-child {
        border-radius: 6px;

        .react-datepicker__day {
          &:first-child {
            border-radius: 0 0 0 6px;
          }
          &:last-child {
            border-radius: 0 0 6px 0;
          }
        }
      }

      .react-datepicker__day--today {
        font-weight: 400;
        font-size: 12px;
      }

      .react-datepicker__day {
        font-weight: 400;
        font-size: 12px;
        color: $dark-purple;
        margin: 0;
        padding: 10px;
        width: 20px;
        height: 20px;
        box-sizing: initial;
        line-height: 1.6;
        box-shadow: 1px 0 0 0 $gray-border,
        0 1px 0 0 $gray-border,
        1px 1px 0 0 $gray-border,
        1px 0 0 0 $gray-border inset,
        0 1px 0 0 $gray-border inset;
      }

      .react-datepicker__day--selected {
        font-weight: 600;
        font-size: 12px;
        color: $white;
        border-radius: 0;
        background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
      }

      .react-datepicker__day--disabled {
        opacity: 0.5;
      }
    }
  }

  .react-datepicker__navigation-icon--previous {
    left: -10px;
  }

  .react-datepicker__navigation--next {
    right: -10px;
  }

  .react-datepicker__navigation-icon::before {
    border-color: $light-purple;
    border-width: 2px 2px 0 0;
    height: 8.62px;
    top: 15px;
  }

  .react-datepicker__day-names {
    margin: 26px 2px 0;
    display: flex;
    justify-content: space-around;

    .react-datepicker__day-name {
      color: $dark-purple;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    font-weight: 400;
    font-size: 12px;
    background: $white;
    border-radius: 0;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0;
  }

  .react-datepicker__day--outside-month {
    background: $lightest-gray;
  }
}

