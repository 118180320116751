@import "../../../styles/colors";
@import "../../../styles/variables";

.pageContainer{
  width: 100%;
  max-width: 1322px!important;

  .section {
    margin: 54px auto 0;
    padding-bottom: 135px;

    @media #{$phones-big} {
      margin-top: 15px;
    }

    .container {
      background-color: $button-purple2;
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 95px 121px;
      width: 100%;

      @media #{$desktop} {
        padding: 75px 85px;
      }

      @media #{$tablets-big} {
        margin: 0 auto 50px auto;
        padding: 50px;
      }

      @media #{$tablets} {
        padding: 16px;
        flex-direction: column;
      }

      @media #{$phones-big} {
        margin-bottom: 32px;
      }
    }
  }
}

