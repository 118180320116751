@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 75px 0 32px 0;
  height: fit-content;
  width: 100%;

  @media #{$tablets} {
    flex-direction: column;
    align-items: center;
  }

  @media #{$phones-big} {
    margin: 32px 0;
  }

  .infoContainer {
    border: 1px solid $gray-border;
    border-radius: 12px;
    padding: 40px 24px 24px;
    width: 60%;
    max-width: 652px;
    height: fit-content;

    @media #{$tablets} {
      max-width: 100%;
      width: 100%;
    }

    @media #{$phones-big} {
      padding: 24px;
    }

    .infoHeading {
      color: $dark-purple;
      font-weight: 700;
      font-size: 32px;
      line-height: 1.4;
      margin-bottom: 24px;

      @media #{$tablets-big} {
        text-align: center;
        font-size: 30px;
      }

      @media #{$tablets} {
        font-size: 26px;
      }

      @media #{$phones-big} {
        font-size: 20px;
      }

      @media #{$phones} {
        font-size: 18px;
      }
    }
  }

  .checkoutContainer {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: $lightest-gray;
    width: 40%;
    max-width: 559px;
    height: fit-content;
    padding: 40px 24px;
    z-index: 1;
    position: relative;
    margin-bottom: 50px;

    @media #{$tablets} {
      max-width: 100%;
      width: 100%;
    }


    @media #{$phones-big} {
      padding: 24px;
    }

    .checkoutHeading {
      color: $dark-purple;
      font-weight: 700;
      font-size: 32px;
      line-height: 1.4;
      margin-bottom: 24px;

      @media #{$tablets-big} {
        text-align: center;
        font-size: 30px;
      }

      @media #{$tablets} {
        font-size: 26px;
      }

      @media #{$phones-big} {
        font-size: 22px;
      }

      @media #{$phones} {
        font-size: 18px;
      }
    }

    .payButtonsContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .payButton {
        border-radius: 8px;
        border: none;
        display: flex;
        justify-content: center;
        padding: 14px 0;
        width: 100%;
      }
    }
  }
}

.button {
  height: 52px;
  padding: 8px 36px;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 1px transparent;
  -webkit-text-fill-color: inherit;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
  }

  span {
    background: -webkit-linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.secureBlock {
  position: absolute;
  bottom: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
  img {
    width: 8px;
    margin-right: 10px;
  }
}

.payIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  div {
    width: 25%;
    height: 30px;
    text-align: center;
    background: #ffffff;
    margin-right: 5px;
    padding: 8px 5px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #E2E2E5FF;
  }
  img {
    width: 100%;
    object-position: center;
    object-fit: contain;
    height: 100%;

  }
}
