@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 17%;
  width: 100%;
  margin-bottom: 20px;

  @media #{$tablets} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
  }

  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    max-width: 552px;

    @media #{$tablets} {
      width: 90%;
    }

    .brandImage {
      border-radius: 24px;
      width: 100%;
      margin-bottom: 16px;
    }

    .terms {
      cursor: pointer;
      text-decoration: underline;
      color: $dark-purple-transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.71;
      margin: 0;

      @media #{$tablets} {
        font-size: 12px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .textContainer {
    width: 50%;
    max-width: 456px;

    @media #{$tablets} {
      width: 100%;
      text-align: center;
    }

    .brandName {
      font-weight: 700;
      font-size: 40px;
      line-height: 1.4;
      margin: 0 0 12px;

      @media #{$desktop} {
        font-size: 38px
      }

      @media #{$tablets-big} {
        font-size: 32px
      }

      @media #{$tablets} {
        font-size: 28px;
      }
    }

    .expirationDate {
      color: $light-purple;
      font-weight: 500;
      font-size: 14px;
      background: -webkit-linear-gradient(308.77deg, $standard-purple 22.27%, $light-purple 115.21%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .cardValueSelection {
      color: $dark-purple-transparent;
      margin: 32px 0 12px;
      line-height: 1.8;

      @media #{$tablets} {
        margin: 16px 0 16px;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media #{$tablets} {
        justify-content: center;
      }

      .customButton {
        border: 1px solid $gray-border;
        -webkit-text-fill-color: $dark-purple;
        margin-right: 16px;
        margin-bottom: 16px;
        padding: 14px 0;
        min-width: 100px;

        @media #{$tablets} {
          margin: 8px;
        }

        &.activeButton {
          background: $white;
          position: relative;
          box-sizing: border-box;
          background-clip: padding-box;
          border: solid 2px transparent;
          -webkit-text-fill-color: inherit;

          &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -2px;
            border-radius: inherit;
            background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
          }
        }

        .customTextStyle {
          color: $dark-purple;
          font-weight: 500;
          font-size: 16px;
          margin: 0 auto;

          &.activeTextStyle {
            background: -webkit-linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .maskContainerInput {
      max-width: 321px;

      @media #{$tablets} {
        margin: 0 auto;
      }
    }

    .giftDescription {
      color: $dark-purple;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.8;
      margin: 32px 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;

      &.readMore {
        -webkit-line-clamp: 200;
        line-clamp: 200;
      }
    }

    .readMoreButton {
      cursor: pointer;
      text-decoration: underline;
      color: $dark-purple-transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      margin: 0;

      &.active {
        background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: underline $light-purple;
        color: $dark-purple-transparent;
      }
    }
  }
}

.leftButton {
  padding: 14px 32px !important;
  height: 100%;

  @media #{$phones-big} {
    padding: 14px 20px !important;
  }
}

.rightButton {
  padding: 10px 20px !important;
  -webkit-text-fill-color: $white;
  -webkit-background-clip: inherit;

  @media #{$phones} {
    padding: 5px !important;
  }
}

.extraButton {
  padding: 10px 20px !important;
  -webkit-text-fill-color: $white;
  -webkit-background-clip: inherit;
  margin-left: 15px;

  @media #{$phones} {
    padding: 5px !important;
    margin-left: 0;
  }
}

.buttonText {
  @media #{$phones} {
    font-size: 12px;
  }
}
