@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  width: 100%;
  max-width: 552px;
  margin: 48px auto 140px;

  @media #{$phones-big} {
    padding-left: 0;
    padding-right: 0;
  }
}
