@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  padding: 66px 0 122px;

  .container {
    border: 1px solid $gray-border;
    border-radius: 7px;
  }
}

.giftoonSmile {
  display: block;
  margin: 33px auto;
}
