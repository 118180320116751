@import "../../../styles/_colors.scss";

.button {
  margin-top: 30px;
  padding: 14px 0;
  width: 40%;
  min-width: 175px;
  background: $pink-white;
  border: none;

  span {
    color: $light-red;
    font-weight: 500;
    font-size: 16px;
    -webkit-text-fill-color: initial;
    margin: 0 auto;
  }
}

.modalWrapper {
  margin: 0 20px;

  .modalContainer {
    background: $white;
    border-radius: 12px;
    padding: 32px;
    max-width: 450px;
    width: 100%;

    .closeButton {
      padding: 0;
      border: none;
      margin-left: auto;
    }

    .textBlock {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;

      .icon {
        max-width: 100px;
        margin: 5px 0 15px;
      }

      h1 {
        color: $dark-purple;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.2;
      }

      p {
        color: $dark-purple-transparent;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.29;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: space-around;

      .modalButton {
        padding: 14px 0;
        width: 45%;

        span {
          font-weight: 500;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
}

