@import "../../../styles/colors";
@import "../../../styles/variables";

.radioButton {
  color: $dark-purple;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  font-weight: 500;
  font-size: 16px;

  &.active {
    &:before {
      width: 19px;
      height: 19px;
      border: none;
      background: $light-purple;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 15px;
    height: 15px;
    transition: background-color 200ms;
    border-radius: 50px;
    border: 2px solid #121127;
  }

  img {
    position: absolute;
    left: 4px;
    top: 10px;
  }

  .imageDisabled {
    display: none;
  }

  input {
    display: none;
  }

  span {
    @media #{$phones-big} {
      font-size: 13px;
    }
  }
}
