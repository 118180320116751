@import "../../../styles/colors";

.line {
  color: $gray-text;
  font-weight: 700;
  font-size: 12px;
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
  margin: 32px 0 30px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $gray-border;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
  }

  span {
    z-index: 2;
    background: white;
    padding: 0 10px;
  }
}