@import "../../../styles/colors";

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gray-border;
  border-radius: 12px;
  width: 360px;

  .cardLink {
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    img {
      position: relative;
      right: 11px;
      width: 106%;
    }
  }

  .cardDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 34px;
    width: 100%;

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: $dark-purple;
    }

    p {
      margin-bottom: 0;
      color: $dark-purple-transparent;
    }
  }
}
