@import "../../../styles/colors";
@import "../../../styles/variables";

.category {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  width: 360px;
  height: 360px;
  margin: 12px 0;

  @media #{$desktop} {
    margin: 12px;
  }

  .heading {
    color: $white;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.4;
    z-index: 1;
    position: absolute;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
