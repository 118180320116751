@import "../../../styles/colors";
@import "../../../styles/variables";

.sendAtText {
  text-align: center;
  margin: 20px 0 53px 0;

  .title {
    color: $gray-text;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  .date {
    color: $dark-purple;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}
