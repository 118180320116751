@import "../../../styles/colors";
@import "../../../styles/variables";

.order {
  color: $gray-text;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;

  @media #{$tablets-big} {
    font-size: 14px
  }

  @media #{$tablets} {
    font-size: 12px;
  }
}

.orderNumber {
  display: flex;
  justify-content: center;
  color: $dark-purple;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  margin: 8px 0 20px;
  padding-bottom: 12px;
  word-break: break-all;

  @media #{$desktop} {
    font-size: 30px
  }

  @media #{$tablets-big} {
    font-size: 28px
  }

  @media #{$tablets} {
    font-size: 26px;
  }
}
