@import "../../../styles/variables";

.giftoonList {
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  margin-bottom: 140px;

  @media #{$desktop} {
    justify-content: center;
  }
}

.giftoonSmile {
  display: block;
  margin: 33px auto;
}