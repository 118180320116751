@import "../../../styles/colors";

.modal {
  background: $modal-background;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
}
