@import "../../../styles/variables";

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .wrapper {
    display: flex;
    gap: 120px;

    @media #{$tablets-big} {
      display: block;
    }

    @media #{$phones-big} {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    margin-bottom: 16px;

    @media #{$tablets-big} {
      margin-bottom: 0;
    }

    @media #{$phones-big} {
      font-size: 28px;
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .description {
    font-size: 18px;
    line-height: 180%;
    margin-bottom: 32px;

    @media #{$tablets-big} {
      margin-top: 0;
      margin-bottom: 16px;
    }

    @media #{$phones-big} {
      font-size: 16px;
      text-align: center;
    }
  }

  .cropFace__wrapper, .controls {
    display: flex;
    flex-direction: column;
    flex: 7
  }
  .controls {
    flex: 5;
    @media #{$tablets-big} {
      margin-top: 16px;
    }
  }

  .sliders {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;

    @media #{$tablets-big} {
      flex-direction: row;
    }

    @media #{$phones-big} {
      flex-direction: column;
    }

    .slider {
      flex: 1;
    }
  }

  .cropFace__wrapper {
    @media #{$tablets-big} {
      height: 450px;
    }
  }
}

.buttonLink {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 14px 20px;
  }
}
