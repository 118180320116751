@import "../../../styles/colors";

.section {
  position: relative;

  .cancelButton {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .inputSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .signInHeading {
    text-align: center;
    margin-bottom: 40px;
  }

  .signInButtonContainer {
    margin: 0;
  }

  .verifyText {
    color: $dark-purple-transparent;
    margin: 16px 0 -10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;

    .link {
      cursor: pointer;
      color: $light-purple;
      text-decoration: underline;
    }
  }

  .alternativeLogIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .blueBtn {
    color: $white;
    background: $facebook-blue;
  }
}
