@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  gap: 16px;
  margin-top: 9%;
  justify-content: space-between;
  width: 100%;

  @media #{$desktop} {
    gap: 5%;
  }

  @media #{$tablets} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .form {
    @media #{$tablets-big} {
      width: 50%;
    }

    @media #{$tablets} {
      width: 90%;
    }

    .heading {
      margin-bottom: 32px;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.4;

      @media #{$tablets} {
        text-align: center;
        font-size: 34px;
      }

      @media #{$phones-big} {
        font-size: 30px;
      }
    }

    .buyOptionsContainer {
      display: flex;
      gap: 24px;
      margin-bottom: 20px;

      @media #{$tablets} {
        justify-content: center;
      }
    }
  }
}

@mixin btn {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 8px 10px;
  }

  @media #{$phones} {
    padding: 4px 5px;
  }
}

.linkButton {
  @include btn;
}

.timeButton {
  @include btn;

  span {
    @media #{$phones-big} {
      display: none;
    }
  }
}
