@import "../../../styles/colors";

.inputSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.signUpHeading {
  text-align: center;
  margin-bottom: 40px;
}

.checkboxContainer {
  display: flex;
  justify-self: flex-start;
  margin: 16px 0 40px;
  width: 100%;
}

.blueBtn {
  color: $white;
  background: $facebook-blue;
}
