@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  position: sticky;
  top: 0;
  left: 0;
  background: $white;
  z-index: 10;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 0 23px;

    @media #{$phones-big} {
      padding: 15px;
    }

    .logo {
      width: 10%;
      display: flex;
      transition: .3s;

      @media #{$tablets} {
        width: 21%;
      }

      img {
        width: 120px;

        @media #{$tablets} {
          width: 80px;
        }
      }
    }

    .title {
      width: 80%;
      text-align: center;

      @media #{$tablets} {
        width: 50%;
        font-size: 16px;
        padding: 0 16px;
      }

      h2 {
        color: $dark-purple;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.4;

        @media #{$tablets} {
          font-size: 16px;
        }
      }
    }

    .linkButton {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      background: transparent;
      text-decoration: none;

      @media #{$tablets} {
        width: 21%;
      }

      .logInButton {
        font-size: 14px;
        padding: 8px 24px;
        border: 1px solid $header-color2;
        background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media #{$phones-big} {
          padding: 6px 20px;
          font-size: 12px;
        }
      }
    }
  }
  .progressLineContainer {
    background: $gray-border;

    hr {
      background: $light-purple;
      margin: 0;
      height: 4px;
      border: none;
    }
  }
}

