@import "../../../styles/colors";
@import "../../../styles/variables";

.container {
  display: flex;
  position: relative;
  justify-content: space-around;
  margin-bottom: 88px;
  width: 100%;

  @media #{$tablets} {
    margin-bottom: 25px;
    flex-wrap: wrap;
  }

  .videoContainer {
    width: 100%;
    max-width: 360px;
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 12px;

    @media #{$tablets} {
      width: 28%;
    }

    @media #{$phones} {
      border-radius: 15px;
    }

    .videoBackground {
      width: 100%;
      object-fit: cover;
      filter: blur(5px);
    }

    .walletVideo {
      height: 100%;
      max-height: 228px;
      width: fit-content;
      margin: 0 auto;

      @media #{$tablets-big} {
        max-height: 240px;
      }

      .video {
        @media #{$phones} {
          border-radius: 15px;
        }

        video {
          @media #{$phones} {
            border-radius: 15px;
          }
        }
      }
    }
  }

  .walletImage {
    width: 100%;
    max-width: 360px;
    border-radius: 12px;
    margin: 0 24px;

    @media #{$tablets} {
      width: 68%;
      margin: 0;
    }
  }

  .onlyImage {
    @media #{$tablets} {
      width: 100%;
      max-width: 100%;
    }
  }

  .giftInfo {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$tablets} {
      margin-top: 15px;
      padding-bottom: 25px;
      max-width: 100%;
      border-bottom: 1px solid $gray-border;
    }

    .userData {
      color: #8E8D98;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.71;
      margin: 0;

      .expirationDate {
        color: #407AFF;
      }
    }
  }

  .couponData {
    .new {
      background-color: $button-purple2;
      color: $light-purple;
      font-weight: 500;
      font-size: 12px;
      line-height: 180%;
      border-radius: 100px;
      padding: 1px 16px;
      width: fit-content;
    }

    .couponName {
      background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.4;
      margin: 3px 0;

      @media #{$tablets} {
        font-size: 16px;
      }
    }

    .couponPrice {
      color: $dark-purple;
      font-weight: 700;
      font-size: 30px;
      line-height: 1.4;

      @media #{$tablets} {
        font-size: 24px;
      }
    }
  }
  .couponButton {
    margin: 0;
  }

  .usedCoupon {
    border: $button-purple2;
    background: $button-purple2;
    -webkit-text-fill-color: $standard-purple;
  }
}
