@import "../../../styles/colors";
@import "../../../styles/variables";

.step {
  display: flex;
  align-items: center;
  margin-bottom: 112px;
  justify-content: space-between;

  @media #{$tablets-big} {
    margin-bottom: 40px;
  }

  @media #{$tablets} {
    flex-direction: column;
    margin-bottom: 25px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media #{$tablets} {
      flex-direction: column;
    }

    .stepText {
      margin-right: 0;
    }
  }

  .stepText {
    position: relative;
    padding: 43px 0 0 34px;
    max-width: 645px;
    width: 48%;
    margin-right: 50px;

    @media #{$tablets-big} {
      margin: 0!important;
      padding: 10px;
    }

    @media #{$tablets} {
      width: 100%;
      text-align: center;
      margin: 0 30px;
    }

    .stepNumber {
      position: absolute;
      left: 0;
      top: 0;
      color: #99348E1F;
      font-weight: 700;
      font-size: 80px;
      line-height: 1.4;
      z-index: -1;

      @media #{$tablets} {
        font-size: 74px;
        top: -30px;
        left: -5px;
      }
    }

    h2 {
      color: $dark-purple;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.8;

      @media #{$tablets-big} {
        font-size: 18px;
        margin-bottom: 10px;
      }

      @media #{$phones-big} {
        font-size: 16px;
      }
    }
  }

  img {
    width: 50%;
    max-width: 625px;
    height: fit-content;

    @media #{$tablets} {
      margin: 0 auto;
      width: 100%;
    }
  }
}
