@import "../../../styles/colors";
@import "../../../styles/variables";


.textContainer {
  width: 50%;
  margin-left: 100px;

  @media #{$tablets-big} {
    margin-top: 50px;
  }

  @media #{$tablets} {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .firstLine {
    color: $dark-purple;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.4;

    @media #{$desktop} {
      font-size: 18px;
    }

    @media #{$tablets} {
      font-size: 16px;
    }
  }

  .secondLine {
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    white-space: nowrap;

    @media #{$desktop} {
      font-size: 38px
    }

    @media #{$tablets-big} {
      font-size: 32px
    }

    @media #{$tablets} {
      font-size: 28px;
    }
  }

  .thirdLine {
    color: $dark-purple;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;

    @media #{$desktop} {
      font-size: 16px;
    }

    @media #{$tablets} {
      font-size: 14px;
    }
  }

  .message {
    margin: 12px 0 32px;
  }

  .getGiftoonButton {
    border: none;
    padding: 14px 32px;

    @media #{$tablets} {
      margin: 0 auto;
    }
  }
}
