@import "../../../styles/colors";

.wrapper {

  border: 1px solid $pale-purple;
  padding: 20px 48px;
  border-radius: 12px;

  .title {
    font-size: 16px;
    line-height: 24px;
    color: $black;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .slider {
    -webkit-appearance: none;
    height: 4px;
    background: $pale-purple;
    border-radius: 100px;
    outline: none;
    width: 100%;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      cursor: pointer;
    }
  }
}
