@import "src/styles/colors";
@import "src/styles/variables";

footer {
  background: $background-gradient;
  color: $white;

  .footerWrapper {
    padding: 56px 0;

    .footerContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      @media #{$tablets} {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 30px;
      }

      @media #{$phones-big} {
        padding: 0 15px;
      }

      .footerNavigationSection {
        width: 70%;
        display: flex;
        justify-content: space-between;

        @media #{$tablets} {
          width: 80%;
          display: flex;
          flex-flow: row wrap;
        }
      }

      .mediaSection {
        display: flex;
        flex-flow: column;
        gap: 24px;
        width: 20%;

        @media #{$tablets-big} {
          width: 25%;
        }

        @media #{$tablets} {
          width: 45%;
        }

        .logo {
          max-width: 200px;
          min-width: 130px;
          width: 100%;
        }
      }
    }

    hr {
      margin: 0 0 30px;
    }

    .infoContainer {
      display: flex;
      padding: 0 20px;

      @media #{$tablets} {
        flex-wrap: wrap;
      }

      .cardImage {
        width: 60px;
        object-fit: contain;
        margin-right: 20px;

        @media #{$tablets} {
          margin: 0 auto 15px;
        }
      }

      .infoText {
        color: white;
        font-weight: 400;
        font-size: 10px;
        white-space: pre-wrap;

        @media #{$tablets} {
          text-align: center;
          margin-bottom: 20px;
        }
      }

      .companyName {
        margin-left: auto;
        padding-left: 20px;

        @media #{$tablets} {
          width: 100%;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
