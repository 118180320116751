@import "../../../styles/colors";
@import "../../../styles/variables";

.modalContainer {
  background-color: $white;
  padding: 35px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media #{$phones-big} {
    padding: 15px;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;

    h3 {
      font-weight: 700;
      font-size: 32px;
      margin: 0;
    }

    .closeButton {
      padding: 0;
    }
  }

  @media #{$tablets} {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 40px;
  }

  .shareButton {
    font-size: 16px;
    padding: 14px 0;
    width: 360px;
    justify-content: center;
    border-radius: 8px;

    @media #{$phones-big} {
      flex-wrap: wrap;
      justify-content: center;
      width: 320px;
    }

    @media #{$phones} {
      flex-wrap: wrap;
      justify-content: center;
      width: 260px;
    }
  }
}
