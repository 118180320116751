@import "../../../styles/colors";
@import "../../../styles/variables";

.container {
  width: 223px;
  height: 100%;
  margin: 0 auto;

  @media #{$phones-big} {
    width: 100%;
    padding: 0 5px;
  }

  .heading {
    color: $standard-purple2;
    font-weight: 600;
    font-size: 23px;
    line-height: 1.8;
    margin-bottom: 38px;
    text-align: center;

    @media #{$phones-big} {
      font-size: 20px;
    }

    @media #{$phones} {
      font-size: 18px;
    }
  }

  .circle {
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border: 1px solid $standard-purple2;
    border-radius: 50px;
    margin: 0 auto 52px;

    .number {
      color: $standard-purple2;
      font-weight: 400;
      font-size: 40px;
      line-height: 1.4;
    }
  }

  .description {
    color: $standard-purple2;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    text-align: center;
  }
}
