@import "../../../styles/colors";
@import "../../../styles/variables";

.senderInfoContainer {
  margin-top: 16px;
  margin-bottom: 9%;

  .senderName {
      color: $black;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 18px;
  }

  .senderInfoInputs {
    display: flex;
    flex-direction: column;
    gap: 15px
  }
}
