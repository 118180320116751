@import "./colors";
@import "./variables";

.contentSection {
  display: flex;
  justify-content: center;
  margin: 56px 0 140px 0;

  h1 {
    text-align: center;
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 12px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.8;
    margin: 45px 0 12px;
  }

  p {
    color: $dark-purple-transparent;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    margin: 0;
  }

  .contentContainer {
    max-width: 744px;

    @media #{$tablets} {
      padding: 0 10px;
    }

    @media #{$tablets} {
      max-width: 100%;
    }

    .highlightedText {
      font-weight: 500;
    }

    a {
      color: #1155CC;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.8;
      margin: 0;
      word-break: break-word;
      display: inline-block;
    }

    ul {
      margin: 0;
      padding-left: 25px;

      li {
        color: $dark-purple-transparent;
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;

        &::marker {
          font-size: 15px;
        }
      }
    }

    .warningContainer {
      background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 92px 192px;
      margin-top: 12px;
      width: 100%;

      @media #{$tablets} {
        padding: 40px;
      }

      @media #{$phones-big} {
        padding: 25px;
      }

      .warning {
        background: $white;
        border-radius: 12px;
        padding: 40px 32px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: center;
        width: 360px;

        @media #{$tablets} {
          width: 75%;
        }

        @media #{$phones-big} {
          width: 90%;
        }

        img {
          width: 56px;
        }

        .warningText {
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.29;
        }
      }
    }
  }
}
