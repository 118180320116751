@import "../../../styles/colors";

.authentificationContainer {

  h1 {
    text-align: center;
    margin-bottom: 8px;
  }

  .enterEmail {
    text-align: center;
    margin: 0;
  }

  .emailInput {
    margin: 40px 0;
    width: 100%;
  }

  .note {
    text-align: center;
    margin-bottom: 0;
    line-height: 1.8;
    margin-top: 32px;

    span {
      color: $dark-purple;
      text-decoration: underline;
    }
  }
}


