@import "../../../styles/colors";

.sendingTimeContainer {
  padding: 32px;
  background: $white;
  border-radius: 12px;
  border: 1px solid $gray-border;
  max-width: 425px;

  .heading {
    color: $dark-purple;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.4;
    margin-bottom: 32px;
  }

  .inputsContainer {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 24px;

    .dateInput {
      cursor: pointer;
    }

    input[type="time"]::-webkit-calendar-picker-indicator{
      position: absolute;
      left: 4px;
      background: url("../../../assets/icons/clockTransparent.svg");
      opacity: 0;
    }
  }
}
