@import "../../../styles/colors";
@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $gray-border;

  .itemSection {
    padding: 16px 0 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 33%;
    border-right: 1px solid $gray-border;
    
    @media #{$tablets} {
      width: 30%;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      padding: 8px 0;
    }

    .itemImage {
      height: 50px;
      border-radius: 4px;
    }

    .itemText {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media #{$tablets} {
        padding: 0 5px;
      }
    }
  }

  .recipientSection {
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $gray-border;

    @media #{$tablets-big} {
      text-align: center;
    }

    @media #{$tablets} {
      width: 25%;
      text-align: center;
    }

    .textContainer {
      width: 80%;

      .recipientName {
        color: $dark-purple;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.8;
        margin: 0;
      }

      .recipientEmail {
        color: #71707D;
        white-space: break-spaces;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.8;
        word-break: break-word;
        margin: 0;
      }
    }
  }

  .dateSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12%;

    @media #{$tablets} {
      width: 15%;
    }

    .date {
      font-weight: 400;
      font-size: 12px;
      color: $dark-purple;

      @media #{ $phones-big} {
        text-align: center;
        word-break: break-word;
      }
    }
  }
}