.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .phoneNumber {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
