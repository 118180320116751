@import "../../../styles/colors";

@mixin icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.label {
  color: $dark-purple;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.9;

  .inputField {
    background: $white;
    position: relative;
    display: flex;
    box-shadow: 0 0 0 1px $gray-border;
    border-radius: 8px;
    width: 100%;

    &.focused {
      box-shadow: none;

      &:before {
        border-radius: 10px;
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -2px;
        padding: 1px;
        background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
      }

      .inputText {
        background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &.error {
      box-shadow: 0 0 0 2px $red;

      .inputText {
        color: $red;

        &:-webkit-autofill {
          -webkit-text-fill-color: $red;
        }
      }
    }

    .disableMarginRight {
      padding-right: 16px!important;
    }

    .disableMarginLeft {
      padding-left: 16px!important;
    }

    .iconRight {
      @include icon;
      height: 25px;
      right: 12px;
      cursor: pointer;
      z-index: 3;
    }

    .iconLeft {
      @include icon;
      left: 12px;
      z-index: 3;
    }

    .inputText {
      border: none;
      font-weight: 400;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      line-height: 1.8;
      background-color: transparent;
      width: 100%;
      padding: 12px 44px;
      border-radius: 8px;
      color: $dark-purple;
      outline: none;
      z-index: 2;

      &::-webkit-input-placeholder {
        color: $dark-blue;
      }

      &:-webkit-autofill {
        -webkit-transition-delay: 9999s;
        transition-delay: 9999s;
        -webkit-text-fill-color: $dark-purple;
      }
    }
  }
}


.errorMessage {
  font-weight: 200;
  font-size: 14px;
  color: $red;
  display: block;
  margin: 5px 15px -10px;
}

