@import "../../../styles/colors";
@import "../../../styles/variables";

.afterInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  color: $dark-purple-transparent;
  width: 100%;
  margin: 20px 0 40px;

  .link {
    color: $dark-purple-transparent;
    text-decoration: none;

    @media #{$phones-big} {
      font-size: 13px;
    }
  }
}