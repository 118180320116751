@import "../../../styles/colors";
@import "../../../styles/variables";

.container {
  background: $white;
  border: 1px solid $gray-border;
  border-radius: 12px;
  width: 90vw;
  max-width: 420px;
  padding: 30px 32px;

  @media #{$phones-big} {
    padding: 15px;
  }

  .headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33.5px;

    @media #{$phones-big} {
      margin-bottom: 12px;
    }

    h1 {
      color: $dark-purple;
      font-weight: 700;
      font-size: 32px;
      line-height: 1.4;

      @media #{$phones-big} {
        font-size: 28px;
      }
    }

    .crossButton {
      border: none;
      padding: 0;
      margin: 0;
    }
  }

  .couponImage {
    width: 100%;
    margin-bottom: 14px;
  }

  h2 {
    background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4;
  }

  h3 {
    color: $dark-purple;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.4;
    margin: 0 0 24px;

    @media #{$phones-big} {
      margin-bottom: 12px;
    }
  }

  .infoBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media #{$phones-big} {
      flex-direction: column;
    }

    h4 {
      color: $dark-purple;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.8;
      margin: 0;
    }

    p {
      color: $dark-purple-transparent;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.8;
      margin: 0;

      @media #{$phones-big} {
        margin-bottom: 12px;
      }
    }
  }

  .barcodeImage {
    width: 100%;
  }

  .couponLink {

    .buttonLink {
      width: 100%;

      .buttonText {
        font-size: 18px;
        font-weight: 400;
        margin: 0 auto;
      }
    }
  }
}
