@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  flex-direction: row;
  margin: 67px 0 120px;

  @media #{$tablets} {
    flex-flow: column;
    align-items: center;
    margin: 30px 0;
  }

  @media #{$phones-big} {
    margin: 0 0 20px;
  }

  :global(.klaviyo-form button) {
    background: linear-gradient(308.77deg, #D62FC3 22.27%, #99348E 115.21%)!important;
    border-radius: 8px!important;
  }

  :global(.klaviyo-form) {
    display: flex;
    align-items: center;
  }

  :global(.klaviyo-form form > div > div:nth-of-type(3) > div) {
    @media #{$phones-big} {
      padding: 15px 6px!important;
    }
  }

  @media #{$phones-big} {
    margin: 0 0 20px;
  }

  .infoContainer {
    width: 60%;
    max-width: 536px;
    margin: 0 59px 28px 0;

    @media #{$tablets} {
      width: 100%;
      margin-right: 0;
    }

    h1 {
      color: $dark-purple;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.4;
      margin: 20px 0 32px;

      @media #{$tablets-big} {
        font-size: 32px
      }

      @media #{$phones-big} {
        font-size: 28px;
      }

      @media #{$tablets} {
        text-align: center;
      }

      @media #{$phones} {
        font-size: 26px;
      }
    }

    img {
      width: 100%;
      margin-bottom: 32px;
    }

    p {
      color: $dark-purple;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.8;
      margin: 0;

      @media #{$tablets} {
        font-size: 16px;
        text-align: center;
      }

      @media #{$phones-big} {
        font-size: 14px;
      }
    }
  }
}
