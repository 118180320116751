@import "../../../styles/colors";

.pinPay {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 34px;

  .focused {
    input {
      background: $white!important;
      -webkit-background-clip: initial!important;
      background-clip: initial!important;

      -webkit-text-fill-color: initial!important;
    }
  }

  .groupInputs {
    display: flex;
    justify-content: space-between;

    label {
      width: 100%;

      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .input, .select {
    background-color: $white!important;
  }
}
