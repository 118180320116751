@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  margin-top: 56px !important;

  h2 {
    text-align: center;
    font-size: 40px;
    color: $dark-purple;

    @media #{$desktop} {
      font-size: 38px
    }

    @media #{$tablets-big} {
      font-size: 32px
    }

    @media #{$tablets} {
      font-size: 28px;
    }
  }

  .couponInput {
    margin: 32px auto 0 auto;
    max-width: 60%;

    @media #{$phones-big} {
      max-width: 80%;
    }

    @media #{$phones} {
      max-width: 100%;
    }
  }
}


.leftButton {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 14px 20px;
  }
}
