@import "../../../styles/colors";

.giftoonInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 38px;
}

.imagePreview {
  float: right;
  width: 100%;
  height: 150px;
  object-fit: contain;
  right: 0;
  clear: both;
  text-align: right;
  object-position: right;
}
