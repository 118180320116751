@import "../../../styles/variables";

.brandList {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  margin-bottom: 140px;
  
  @media #{$desktop} {
    justify-content: center;
  }
}

.giftoonSmile {
  display: block;
  margin: 33px auto;
}
