@import '../../../styles/colors';

.buttonList {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;
  padding: 32px 0;

  button {
    -webkit-background-clip: text;
    -webkit-text-fill-color: $dark-purple;
    background: $white;
  }

  .activeButton {
    -webkit-text-fill-color: $white;
    background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
    border: none!important;
  }
}
