@import "../../../styles/colors";
@import "../../../styles/variables";

.block {
  position: relative;
  width: 100%;
  background: $lightest-pink;
  overflow: hidden;
  margin-bottom: 80px;

  @media #{$tablets-big} {
    margin-bottom: 40px;
  }

  @media #{$phones-big} {
    margin-bottom: 30px;
  }

  .container {
    display: flex;
    padding-top: 143px;
    padding-bottom: 130px;

    @media #{$tablets-big} {
      padding-top: 110px;
      padding-bottom: 100px;
    }

    @media #{$tablets} {
      padding-top: 50px;
      flex-direction: column;
      padding-bottom: 0;
    }

    .left {
      max-width: 680px;
      width: 55%;

      @media #{$tablets} {
        width: 100%;
        margin-bottom: 30px;
      }

      .shortText {
        color: $standard-purple2;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.38;
        margin: 0 0 27px;

        @media #{$desktop} {
          font-size: 36px
        }

        @media #{$tablets-big} {
          font-size: 22px
        }

        @media #{$tablets} {
          text-align: center;
          margin-bottom: 15px;
        }

        @media #{$phones-big} {
          font-size: 18px;
        }
      }

      .heading {
        color: $standard-purple2;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.42;
        margin-bottom: 29px;

        @media #{$desktop} {
          font-size: 36px
        }

        @media #{$tablets-big} {
          font-size: 32px
        }

        @media #{$tablets} {
          text-align: center;
          font-size: 28px;
          margin-bottom: 15px;
        }

        @media #{$phones-big} {
          font-size: 22px;
        }
      }

      .description {
        color: $standard-purple2;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.75;
        margin: 0 0 59px;

        @media #{$tablets} {
          font-size: 18px;
          text-align: center;
          margin-bottom: 20px;
        }
      }

      .button {
        padding: 14px 35px;
        background: $white;
        -webkit-text-fill-color: $standard-purple;
        border-radius: 15px;
        border: 2px solid $standard-purple2;

        @media #{$tablets} {
          margin: 0 auto;
        }

        span {
          font-weight: 500;
          font-size: 18px;
          text-transform: uppercase;
        }
      }
    }
  }

  .phoneImage {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 500px;
    width: 45%;

    @media #{$tablets} {
      width: 50%;
      position: static;
      margin: 0 auto;
    }
  }
}
