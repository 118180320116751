@import "../../../styles/colors";
@import "../../../styles/variables";

@mixin heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  h2 {
    font-weight: 500;
    font-size: 12px;
  }
}

.headingsContainer {
  border-radius: 6px 6px 0 0;
  background: $lightest-gray;
  width: 100%;
  display: flex;

  .itemHeading {
    @include heading;
    border-right: 1px solid $gray-border;
    width: 33%;

    @media #{$tablets} {
      width: 30%;
    }
  }

  .recipientHeading {
    @include heading;
    border-right: 1px solid $gray-border;
    width: 22%;

    @media #{$tablets} {
      width: 25%;
    }
  }

  .dateHeading {
    @include heading;
    width: 12%;

    @media #{$tablets} {
      width: 15%;
    }
  }
}
