@import "../../../styles/colors";

@mixin icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.label {
  color: $dark-purple;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.9;
}

.inputField {
  position: relative;
  box-shadow: 0 0 0 1px $gray-border;
  border-radius: 8px;
  width: 100%;

  &.focused {
    box-shadow: 0 0 0 2px $standard-purple;
  }

  &.error {
    box-shadow: 0 0 0 2px $red;

    .inputText {
      color: $red;

      &:-webkit-autofill {
        -webkit-text-fill-color: $red;
      }
    }
  }

  .iconRight {
    @include icon;
    height: 25px;
    right: 12px;
    cursor: pointer;
  }

  .iconLeft {
    @include icon;
    left: 12px;
    z-index: -1;
  }

  .inputText {
    resize: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    width: 100%;
    padding: 12px 44px 12px 12px;
    border-radius: 8px;
    color: $dark-purple;
    outline: none;

    &::-webkit-input-placeholder {
      color: $dark-blue;
    }

    &:-webkit-autofill {
      -webkit-transition-delay: 9999s;
      transition-delay: 9999s;
      -webkit-text-fill-color: $dark-purple;
    }
  }
}

.errorMessage {
  font-weight: 200;
  font-size: 14px;
  color: $red;
  display: block;
  margin: 5px 15px -10px;
}
