@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  margin-top: 56px;

  @media #{$tablets-big} {
    margin-bottom: 30px;
  }

  .heading {
    text-align: center;
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 48px;

    @media #{$tablets-big} {
      font-size: 34px;
      margin-bottom: 24px;
    }
  }

  .couponWrapper {
    padding: 32px;
    border: 1px solid $gray-border;
    border-radius: 12px;
  }

  .button {
    margin: 48px auto 0;
    border: none;
    padding: 14px 50px;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: $dark-blue3;
    background: $lightest-gray;
  }
}

.coupon {
  margin-bottom: 0;
}
