.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .pageContainerStyle {
    display: flex;
    justify-content: center;
    flex: 1;
  }
}