@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  justify-content: center;
  margin: 72px 0 32px 0;
  width: 100%;
  height: fit-content;

  @media #{$phones-big} {
    margin: 32px 0 32px 0;
  }

  .container {
    text-align: center;
    border: 1px solid $gray-border;
    border-radius: 12px;
    padding: 40px;

    @media #{$phones-big} {
      padding: 15px;
    }

    .textContainer {
      margin: 0 auto;
      max-width: 300px;

      .heading {
        color: $dark-purple;
        font-weight: 700;
        font-size: 32px;
        line-height: 1.4;

        @media #{$desktop} {
          font-size: 30px
        }

        @media #{$tablets-big} {
          font-size: 28px
        }

        @media #{$tablets} {
          font-size: 26px;
        }
      }

      .description {
        color: $gray-text;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        margin: 40px 0 32px;


        @media #{$tablets-big} {
          font-size: 14px
        }

        @media #{$tablets} {
          font-size: 12px;
        }

        span {
          color: $dark-purple;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      padding: 14px 0;
      width: 360px;
      margin: 0 auto;

      @media #{$phones-big} {
        width: 100%
      }
    }
  }
}
