@import "../../../styles/variables";

.section {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 72px;

  .wrapper {
    display: flex;
    gap: 120px;
    flex: 1;

    @media #{$tablets-big} {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    @media #{$tablets-big} {
      align-items: center;
    }

    .header {
      font-weight: 700;
      font-size: 40px;
      margin-bottom: 32px;

      @media #{$tablets-big} {
        margin-bottom: 16px;
      }

      @media #{$tablets-big} {
        margin-bottom: 32px;
      }
    }
  }
}

.button {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 14px 20px;
  }
}
