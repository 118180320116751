@import "../../../styles/colors";

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  text-align: center;
  color: $dark-purple-transparent;
  margin-top: 24px;
  margin-bottom: 0;

  .link {
    color: $light-purple;
    text-decoration: underline;
  }
}