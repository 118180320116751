@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  margin: 72px 0 140px 0;

  @media #{$tablets} {
    margin: 30px 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
      width: 100%;
      max-width: 440px;
      margin: 0 auto;
      border-radius: 12px;
      border: 1px solid $gray-border;
      padding: 40px;

      @media #{$phones-big} {
        padding: 20px;
      }

      h1 {
        color: $dark-purple;
        font-weight: 700;
        font-size: 32px;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 8px;
      }

      .description {
        color: $dark-purple-transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.8;
        text-align: center;
        margin-bottom: 40px;

        @media #{$phones-big} {
          margin-bottom: 20px;
          line-height: 1.5;
        }
      }

      .inputContainer {
        margin-bottom: 40px;

        @media #{$phones-big} {
          margin-bottom: 20px;
        }
      }

      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 14px 0;
      }
    }
  }
}
