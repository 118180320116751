@import "../../../styles/colors";
@import "../../../styles/variables";

.block {
  margin-bottom: 80px;

  @media #{$tablets-big} {
    margin-bottom: 50px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      color: $standard-purple2;
      font-weight: 500;
      font-size: 40px;
      line-height: 1.4;

      @media #{$desktop} {
        font-size: 38px
      }

      @media #{$tablets-big} {
        font-size: 32px
      }

      @media #{$tablets} {
        font-size: 28px;
      }
    }

    .line {
      width: 100%;
      max-width: 300px;
      margin-bottom: 72px;
    }

    .guide {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media #{$tablets} {
        flex-direction: column;
        align-items: center;
      }

      @media #{$tablets} {
        display: none;
      }

      &::after {
        position: absolute;
        content: '';
        width: 80%;
        height: 1px;
        background: $standard-purple2;
        top: 41%;
        left: 10%;
        z-index: -1;

        @media #{$tablets-big} {
          top: 37%;
        }
      }
    }

    .slider {
      display: none;

      @media #{$tablets} {
        display: block;
        width: 100%;
      }
    }
  }
}
