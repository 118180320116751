@import "../../../styles/colors";
@import "../../../styles/variables";

.modalContainer {
  background-color: $white;
  padding: 35px;
  border-radius: 12px;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;

    h3 {
      font-weight: 700;
      font-size: 32px;
      margin: 0;
    }

    .closeButton {
      padding: 0;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 360px;

    @media #{$phones-big} {
      flex-wrap: wrap;
      justify-content: center;
      width: 320px;
    }

    @media #{$phones} {
      flex-wrap: wrap;
      justify-content: center;
      width: 260px;
    }
  }
}
