@import "../../../styles/colors";

.text {
  text-align: center;
  color: $dark-purple-transparent;
  margin-top: 24px;
  margin-bottom: 0;

  .link {
    color: $light-purple;
    text-decoration: underline;
  }
}