@import "../../../styles/colors";

.priceLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .payText {
    color: $gray-text;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
  }

  .price {
    color: $dark-purple;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
  }
}

.totalPriceLine {
  margin: 19px 0 32px;

  .price {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    margin: 0;
  }
}
