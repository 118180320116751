$white: #fff;
$white-transparent: #FFFFFF00;
$black-transparent: #00000080;
$header-color1: #FFD8FB38;
$header-color2: #99348E1E;
$color-text: #545363;
$black: #000;
$light-gray: #B7B7B7;
$dark-gray: #FFFFFF66;
$gray-border: #E2E2E5FF;
$white-blue: #E1F6FF;
$gray-text: #A0A0A9;
$gray-transparent: #FFFFFF1E;
$light-pink: #F6C5F1;
$lightest-pink: #E8D0F7;
$lightest-pink2: #F9F3F8;
$lightest-pink3: #FBF0F6;
$pink-white: #FFDFDF;
$pale-purple: #F2E6F1;
$border-white: #F7EFF6;
$lightest-gray: #F3F3F6;
$light-purple: #D62FC3;
$standard-purple: #99348E;
$standard-purple2: #98358E;
$button-border: #99348E1E;
$button-purple2: #99348E1A;
$button-background: #FFD8FB38;
$dark-purple: #321C30;
$dark-purple-transparent: #321C30B7;
$purple-text: #FD92F2;
$purple-description: #F7C5F1;
$purple-border: #99348E1F;
$red: #DC2626;
$light-red: #E84057;
$modal-background: #000000CC;
$dark-blue: #12112766;
$dark-blue2: #121127B8;
$dark-blue3: #121127;
$dark-blue4: #1211278F;
$gray-blue: #DEDEE6;

$baner-slide-color1: #8547DF;
$facebook-blue: #3B5998;

$banner-gradient: linear-gradient(320deg, #EF2779 0.79%, #7834BE 79.29%);
$purple-gradient: linear-gradient(63deg, rgba(236, 39, 226, 0.92) 0.79%, #7834BE 79.29%);

$background-gradient: linear-gradient(0.25turn, #98358e, #66549c);
