@import "../../../styles/colors";
@import "../../../styles/variables";

.slide {
  display: flex;
  align-items: center;
  position: relative;
  background-size: 100% auto;
  background-image: $background-gradient;
  min-height: 565px;
  width: 100%;
  padding: 184px 0 73px;
  margin-bottom: 80px;

  @media #{$tablets-big} {
    padding: 80px 0 50px;
    margin-bottom: 50px;
  }

  .container {
    width: 100%;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      max-width: 650px;
      z-index: 2;

      @media #{$desktop} {
        margin-right: 20px;
      }

      @media #{$tablets} {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        align-items: center;
      }

      h1 {
        font-weight: 400;
        font-size: 56px;
        line-height: 1.4;
        color: $white;
        white-space: pre-wrap;
        width: 100%;
        margin-bottom: 20px;

        @media #{$desktop} {
          font-size: 40px;
        }

        @media #{$tablets-big} {
          font-size: 38px;
        }

        @media #{$tablets} {
          font-size: 34px;
        }

        @media #{$phones-big} {
          font-size: 30px;
        }

        @media #{$phones} {
          font-size: 28px;
        }

        span {
          position: relative;
          font-weight: 700;

          .line {
            width: 100%;
            position: absolute;
            top: 87%;
            right: 0;
          }
        }
      }

      .customText {
        color: $white;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.75;
        white-space: pre-wrap;
        margin: 0 0 30px;

        @media #{$tablets-big} {
          font-size: 18px;
        }

        @media #{$phones-big} {
          font-size: 16px;
        }
      }

      .button {
        padding: 14px 35px;
        background: $white;
        -webkit-text-fill-color: $standard-purple;
        border-radius: 15px;

        @media #{$tablets} {
          margin: 0 auto;
        }

        @media #{$phones-big} {
          padding: 12px 25px;
        }

        span {
          font-weight: 500;
          font-size: 18px;
          text-transform: uppercase;

          @media #{$phones-big} {
            font-size: 14px;
          }
        }
      }
    }

    .homeBanner {
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: 580px;
      width: 50%;

      @media #{$tablets-big} {
        max-width: 750px;
      }

      @media #{$tablets} {
        display: none;
      }
    }

    .homeBannerCircle {
      display: none;
      width: 100%;
      max-width: 340px;
      margin: 0 auto;

      @media #{$tablets} {
        display: block;
      }
    }
  }
}

