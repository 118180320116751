@import "../../../styles/colors";

.section {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    border-radius: 12px;
    border: 1px solid $gray-border;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 40px 0;
    width: 360px;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      max-width: 184px;
    }

    img {
      width: 120px;
    }
  }
}