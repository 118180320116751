.videoContainer {
  width: 100%;
  max-width: 552px;
  display: flex;
  align-items: center;
  position: relative;

  .reactPlayer {
    border-radius: 24px;
    display: flex;

    video {
      border-radius: 24px;
    }

    &.disableFullscreen {
      video {
        &::-webkit-media-controls-fullscreen-button {
          display: none;
        }
      }
    }
  }

  .playButton {
    cursor: pointer;
    background: transparent;
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%);
  }
}
