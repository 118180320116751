@import '../../../styles/colors';
@import '../../../styles/variables';

.pageContainer {
  max-width: 1280px!important;

  .section {
    margin: 56px 0 140px;
    padding: 0 20px;

    @media #{$tablets-big} {
      margin: 40px 0;
    }

    h1 {
      text-align: center;
      color: $dark-purple;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.4;

      @media #{$tablets} {
        font-size: 38px;
      }
    }

    p {
      color: $dark-purple-transparent;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.8;
      margin: 0 0 14px;

      @media #{$tablets-big} {
        font-size: 16px;
      }

      @media #{$tablets} {
        font-size: 14px;
      }

      @media #{$phones-big} {
        font-size: 12px;
      }
    }

    .smallText {
      color: $dark-purple-transparent;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.8;

      @media #{$tablets} {
        font-size: 12px;
      }

      @media #{$phones-big} {
        font-size: 10px;
      }

      a {
        color: #407AFF;
        text-decoration: underline;
      }
    }

    .stepsContainer {
      display: flex;
      flex-direction: column;

      @media #{$tablets-big} {
        gap: 25px;
      }
    }
    .button {
      margin: 0 auto;
      padding: 14px 32px;
    }
  }
}
