@import "../../../styles/variables";

.imagePreview {
  width: 50%;
  object-fit: contain;

  @media #{$tablets} {
    width: 100%;
    margin-bottom: 32px;
  }
}

.videoContainer {
  width: fit-content;
  height: fit-content;

  video {
    max-height: 600px;

    @media #{$tablets-big} {
      max-height: 450px;
    }

    @media #{$tablets} {
      margin-bottom: 32px;
    }
  }
}