@import "../../../styles/colors";
@import "../../../styles/variables";

.mobileNav {
  display: none;
  position: fixed;
  background: $white;
  top: 60px;
  width: 100%;
  z-index: 40;
  padding-bottom: 30px;
  min-height: calc(100vh - 60px);
  transform: translateX(calc(-100%));
  transition: 0.5s;

  &.active {
    transform: translateY(0);
  }

  @media #{$tablets} {
    display: flex;
    flex-direction: column;
  }

  nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 20px;
    padding-top: 20px;
    flex: 1;

    li {
      text-align: center;

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $dark-purple-transparent;
        text-decoration: none;

        &.active {
          color: $standard-purple;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 30px;

    .logInButton {
      color: green;
      text-decoration: none;
      width: 150px;
      justify-content: center;
      padding: 8px 10px;
    }
  }
}


