@import "../../../styles/colors";
@import "../../../styles/variables";

:global(.active) {
  color: $standard-purple;
}

.transparent {
  box-shadow: none;
  background: transparent;

  .container {
    .logo {
      filter: brightness(0) invert(1);
    }

    nav {
      li {
        a {
          color: $white;

          &.active, &:hover {
            color: $black;
          }
        }
      }
    }

    .logInButton {
      background: $header-color1;
      border: 1px solid $header-color2;
      -webkit-text-fill-color: $white;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 27px 0;
  z-index: 50;

  @media #{$tablets} {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  @media #{$phones-big} {
    padding: 0 15px;
  }

  .logo {
    width: 26%;
    display: flex;
    transition: .3s;

    .logoLink {
      max-width: 200px;
      min-width: 130px;
      width: 75%;

      img {
        width: 100%;
      }
    }
  }

  nav {
    display: flex;
    list-style: none;
    align-items: center;
    gap: 32px;

    @media #{$tablets} {
      display: none;
    }

    li {

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $dark-purple-transparent;
        text-decoration: none;
        transition: .3s;
        text-transform: uppercase;

        &.active, &:hover {
          color: $standard-purple;
        }
      }
    }
  }

  .buttonsContainer {
    width: 26%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    transition: .3s;

    @media #{$tablets-big} {
      gap: 10px;
    }

    @media #{$tablets} {
      display: none;
    }

    .linkButton {
      text-decoration: none;
      transition: .3s;

      .logInButton {
        transition: .3s;

        img {
          transition: .3s;
        }

        @media #{$tablets-big} {
          padding: 8px 10px;
        }
      }
    }
  }
}

