@import "../../../styles/colors";

.myInfoSection {
  padding-bottom: 40px;
  border-bottom: 1px solid $gray-border;

  .myInfoHeading {
    color: $dark-purple;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .saveButton {
    background: $purple-border;
    color: $light-purple;
    -webkit-text-fill-color: $light-purple;
    -webkit-background-clip: inherit;
    border: none;
    padding: 14px 32px;
    margin-top: 32px;

    &:disabled {
      background: $gray-border;
      color: $standard-purple;
      -webkit-text-fill-color: $standard-purple;
    }
  }
}