@import "../../../styles/colors";
@import "../../../styles/variables";

.burger {
  background: transparent;
  display: none;
  gap: 3px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media #{$tablets} {
    display: flex;
  }

  .burgerLine {
    height: 4px;
    width: 20px;
    background: $white;
    border-radius: 5px;

    &.coloredLine {
      background: $light-purple!important;
    }
  }
}


