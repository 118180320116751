@import "../../../styles/colors";

.authentificationContainer {

  h1 {
    text-align: center;
    margin-bottom: 8px;
  }

  .enterEmail {
    text-align: center;
    margin: 0;
  }

  .passwordInput {
    margin: 40px 0;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
}


