@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  margin-top: 56px;

  .profileHeading {
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 12px;
  }

  .profileTabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid $gray-border;

    .tab {
      color: $dark-purple;
      padding: 10px 24px 8px;
      cursor: pointer;

      @media #{$phones-big} {
        text-align: center;
        padding: 10px 15px 8px 15px;
      }

      @media #{$phones} {
        padding: 10px 8px 8px 8px;
      }

      &.activeTab {
        background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 1px solid $standard-purple;
        position: relative;
        top: 1px;
      }

      .tabHeading {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

