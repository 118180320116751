@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;

  @media #{$tablets} {
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
  }

  .videoContainer {
    width: fit-content;
    height: fit-content;

    video {
      max-height: 600px;

      @media #{$tablets-big} {
        max-height: 450px;
      }
    }
  }

  .textContainer {
    width: 50%;
    max-width: 456px;
    display: flex;
    flex-direction: column;

    @media #{$tablets} {
      max-width: 100%;
      width: 100%;
    }

    .heading {
      color: $dark-purple;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.4;
      margin-bottom: 15px;

      @media #{$desktop} {
        font-size: 38px;
      }

      @media #{$tablets-big} {
        font-size: 32px;
        text-align: center;
      }

      @media #{$tablets} {
        font-size: 28px;
        text-align: center;
      }
    }

    .description {
      color: $dark-purple-transparent;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.8;
      margin: 0 0 32px;

      @media #{$tablets-big} {
        font-size: 16px;
        text-align: center;
      }
    }

    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @media #{$tablets-big} {
        justify-content: center;
      }
    }
  }
}

.photoButton {
  display: flex;
  justify-content: center;
  -webkit-text-fill-color: $dark-purple;
  width: 220px;
  padding: 14px 0;
  border: 1px solid $purple-border;
  border-radius: 6px;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 500;
    line-height: 22px;
    font-size: 14px;
  }
}

.input {
  display: flex;
  justify-content: center;
  color: $dark-purple-transparent;
  width: 220px;
  padding: 14px 0;

  span {
    font-weight: 500;
    line-height: 22px;
    font-size: 14px;
  }
}

.footerButton {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 14px 20px;
  }
}
