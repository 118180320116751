@import "../../../styles/colors";
@import "../../../styles/variables";

.checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  color: $dark-purple-transparent;

  @media #{$phones-big} {
    padding-left: 24px;
  }

  &.active {
    &:before {
      background: $light-purple;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    border: 1px solid $gray-blue;
    transition: background-color 200ms;
  }

  img {
    position: absolute;
    left: 3px;
    top: 9px;
  }

  .imageDisabled {
    display: none;
  }

  input {
    position: absolute;
    display: none;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    color: $dark-blue2;

    @media #{$phones-big} {
      font-size: 13px;
    }
  }
}

.roundCheckbox {
  font-weight: 500;
  font-size: 16px;
  color: $dark-purple;

  &::before {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    border: 2px solid #121127;
  }

  &.active {
    &:before {
      width: 19px;
      height: 19px;
      border: none;
    }
  }

  img {
    position: absolute;
    left: 4px;
    top: 10px;
  }
}
