@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  margin: 56px 0;
  text-align: center;

  .heading {
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 12px;

    @media #{$desktop} {
      font-size: 38px
    }

    @media #{$tablets-big} {
      font-size: 32px
    }

    @media #{$tablets} {
      font-size: 28px;
    }
  }

  .description {
    color: $dark-purple-transparent;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    margin: 0 0 16px;

    @media #{$desktop} {
      font-size: 16px
    }
  }

  .cardContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;

    @media #{$desktop} {
      justify-content: center;
    }
  }
}

.leftButton {
  padding: 14px 32px;

  @media #{$phones-big} {
    padding: 14px 20px;
  }
}
