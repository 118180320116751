@import "../../../styles/colors";
@import "../../../styles/variables";

.block {
  margin-bottom: 120px;

  @media #{$tablets-big} {
    margin-bottom: 80px;
  }

  @media #{$phones-big} {
    margin-bottom: 50px;
  }

  .container {

    .heading {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      background: $banner-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 25px;

      @media #{$tablets-big} {
        font-size: 24px;
      }

      @media #{$phones-big} {
        font-size: 20px;
      }
    }

    .imageContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      @media #{$phones-big} {
        justify-content: center;
      }

      .image {
        object-fit: contain;
        width: 20%;

        @media #{$tablets} {
          width: 40%;
          margin: 30px 20px;
        }

        @media #{$phones-big} {
          width: 60%;
          margin: 0 0 55px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
