@import "../../../styles/colors";
@import "../../../styles/variables";


.webcamWindow {
  position: relative;
  background: $white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 90%;
  max-width: 650px;
  padding: 30px;
  gap: 16px;
  margin: 0 auto;

  video {
    border-radius: 24px;
    border: 5px solid $light-purple;
    width: 100%;
  }
}

.button {
  background: linear-gradient($light-purple, $standard-purple);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
}

.cameraButton {
  img {
    filter: invert(80%) sepia(100%) saturate(0%) hue-rotate(101deg) brightness(115%) contrast(101%);
  }
}

.crossButton {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 40px;
  height: 40px;
}