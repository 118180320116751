@import "../../../styles/colors";
@import "../../../styles/variables";

.pageContainer {
  max-width: 1322px!important;
  width: 100%;

  .section {
    background-color: #99348E1A;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 54px 0;
    width: 100%;
    max-width: 1322px;
    min-height: 600px;
    margin: 54px auto 0;


    @media #{$phones-big} {
      padding: 20px;
      min-height: fit-content;
      margin-top: 15px;
    }

    .videoContainer {
      position: relative;
      width: fit-content;
      height: fit-content;

      .video {
        max-height: 570px;
        width: fit-content;

        video {
          max-height: 570px;
        }
      }
    }
  }
}
