@import "../../../styles/colors";

.modal {
  background: $white;
  border-radius: 12px;
  padding: 24px 32px 32px;
  max-width: 360px;
  width: 100%;

  .closeButton {
    padding: 0;
    border: none;
    margin-left: auto;
  }

  .textBlock {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    .smile {
      width: 44px;
      margin: 5px 0 15px;
    }

    h1 {
      color: $dark-purple;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 8px;
    }

    p {
      color: $dark-purple-transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.29;
      margin: 0;
    }
  }

  .buttonContainer {
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;

    .gradientButton {
      display: flex;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      width: 100%;
    }

    .pinkButton {
      background: $header-color2;
      -webkit-text-fill-color: $standard-purple;
      border: none;
      display: flex;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      width: 100%;
    }
  }
}