@import "../../../styles/colors";
@import "../../../styles/variables";

.section {
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 80px;

  @media #{$tablets-big} {
    margin-bottom: 40px;
  }

  @media #{$phones-big} {
    margin-bottom: 30px;
  }

  :global(.slick-track) {
    display: flex !important;
  }

  :global(.slick-slide) {
    height: inherit !important;

    & > div {
      height: 100%;
    }
  }

  h2 {
    text-align: center;
    color: $dark-purple;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 53px;

    @media #{$desktop} {
      font-size: 38px;
    }

    @media #{$tablets-big} {
      font-size: 32px;
      margin-bottom: 35px;
    }

    @media #{$tablets} {
      font-size: 28px;
    }
  }

  .slider {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 156px;
      background: linear-gradient(270deg, $white 0%, $white-transparent 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 2;

      @media #{$tablets-big} {
        width: 110px;
      }

      @media #{$tablets} {
        width: 85px;
      }

      @media #{$phones-big} {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 156px;
      background: linear-gradient(270deg, $white 0%, $white-transparent 100%);
      z-index: 2;

      @media #{$tablets-big} {
        width: 110px;
      }

      @media #{$tablets} {
        width: 85px;
      }

      @media #{$phones-big} {
        display: none;
      }
    }

    .sliderItem {
      padding: 0 12px;
      height: 100%;

      @media #{$phones-big} {
        padding: 0 8px;
      }

      .itemContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(50, 28, 48, 0.12);
        border-radius: 12px;
        height: 100%;

        .cardLink {
          border-radius: 12px 12px 0 0;
          overflow: hidden;
          width: 100%;

          .sliderImage {
            border-radius: 12px 12px 0 0;
            position: relative;
            right: 11px;
            width: 104%;

            @media #{$tablets-big} {
              right: 5px;
              width: 102%;
            }
          }
        }

        .itemContent {
          padding: 32px 30px;


          @media #{$phones-big} {
            padding: 15px;
          }

          h4 {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            line-height: 1.4;
            color: $dark-purple;
            margin-bottom: 12px;

            @media #{$tablets-big} {
              font-size: 18px;
            }

            @media #{$tablets} {
              font-size: 16px;
            }
          }

          span {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 1.8;
            color: $dark-purple-transparent;

            @media #{$tablets-big} {
              font-size: 16px;
            }

            @media #{$tablets} {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
