@import "../../../styles/colors";

.promoLine {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  text-decoration: underline;
  position: relative;
  border: none;
  padding: 0 0 0 25px;
  color: $color-text;
  -webkit-text-fill-color: $color-text;
  -webkit-background-clip: inherit;
  background: transparent;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 15px;
    height: 20px;
    background: url("../../../assets/icons/promo.png") no-repeat center;
    background-size: contain;

  }
}
.enterPromoLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  label {
    width: 70%;
  }
  input {
    background: $white!important;
    -webkit-background-clip: initial!important;
    background-clip: initial!important;

    -webkit-text-fill-color: initial!important;
  }

  &.applied {
    label {
      opacity: 0.5;
    }
  }
}

.promoError {
  font-weight: 200;
  font-size: 14px;
  color: #DC2626;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .resetLink {
    padding: 0 0 0 6px;
    border: none;
    text-decoration: underline;
    text-transform: lowercase;
    color: #DC2626;
    background: transparent!important;
    -webkit-background-clip: initial!important;
    background-clip: initial!important;
    -webkit-text-fill-color: initial!important;
  }
}

