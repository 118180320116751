@import "../../../styles/colors";

.btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  font-weight: 500;
  line-height: 22px;
  font-size: 14px;
  padding: 8px 24px;
  width: fit-content;
  background: -webkit-linear-gradient($light-purple, $standard-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid $purple-border;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
}

.filled {
  background: linear-gradient(308.77deg, $light-purple 22.27%, $standard-purple 115.21%);
  color: $white;
  -webkit-text-fill-color: $white;
  -webkit-background-clip: inherit;
}
